<template>
  <v-container>
    <head-title
      text="Nuevo Proveedor"
      :to="{ name: 'accounting-provider_list' }"
    ></head-title>
    <v-form ref="form" v-model="valid" :lazy-validation="false">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Nombre"
            :rules="textRules"
            v-model="body.name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Dirección"
            :rules="textRules"
            v-model="body.address"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Número de Teléfono"
            :rules="textRules"
            v-model="body.phone_number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Email"
            :rules="textRules"
            v-model="body.email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Número de Cuenta"
            :rules="textRules"
            v-model="body.account_bank"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="NIT"
            :rules="textRules"
            v-model="body.nit"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="1">
          <v-checkbox
            v-model="body.idp"
            :label="`IDP`"
            input-value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox
            v-model="body.disable_iva"
            :label="`Pequeño Contribuyente`"
            input-value="false"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="1">
          <v-checkbox
            v-model="body.isr"
            :label="`ISR`"
            input-value="false"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox
            v-model="body.credit_available"
            :label="`Crédito Disponible`"
            input-value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6" v-if="body.credit_available">
          <v-text-field
            label="Días  de Crédito"
            :rules="textRules"
            v-model="body.credit_days"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="yellow" @click="resetForm()"> Borrar </v-btn>
        <v-btn color="success" @click="createProvider()" :disabled="!valid">
          Registrar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>
<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
  },
  data: () => ({
    valid: false,

    body: {
      name: "",
      address: "",
      nit: "",
      phone_number: "",
      email: "",
      account_bank: "",
      credit_day: "",
      idp: "",
    },

    //rules
    textRules: [rules.required, rules.minLength(5)],
    onlyRequired: [rules.required],
    valueRules: [rules.required, rules.minValue(0.1)],
  }),
  methods: {
    createProvider() {
      requests.post("api/catalogs/providers/", this.body).then((res) => {
        if (res.status == 201) {
          this.resetForm();
          this.$toasted.global.info({ message: "Registro Creado" });
        }
      });
    },
    resetForm() {
      this.body = {
        name: "",
        address: "",
        nit: "",
        phone_number: "",
        email: "",
        account_bank: "",
        credit_day: "",
      };
    },
  },
};
</script>